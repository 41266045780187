:root{
  --primary:rgb(13, 63, 138) ;
  --darkblue:#000715 ;
  --secondary:rgb(172, 172, 172);
  --success:rgb(28, 146, 44);
  --danger:rgb(243, 0, 0);
  --warning:rgb(223, 189, 0);
  --light:rgb(246, 249, 254);
  --dark:rgb(34, 34, 34);
  --muted:rgb(94, 106, 114);
  --white:rgb(255, 255, 255);
  --border:rgb(0, 0, 0, 0.1);
  --skyblue: rgb(34, 177, 255);
  --linear-gradient: linear-gradient(-160deg,#4b3eff 0,#22b1ff 99%);
}

body{
  /* font-family: "Open Sans", sans-serif !important; */
  font-family: "Lexend", Sans-serif !important;
}
.content-body{
  min-height: 48.2vh;
}
.btn-light {
  transition: background-color 0.3s ease !important; /* Smooth transition for background color */
}

.btn-light:hover {
  background-color:  #22B1FF !important;
  color: var(--white) !important;
  border: 0;
}
.btn-primary{
  z-index: 1;
  color: #fff;
  height: 55px;
  min-width: 150px;
  font-size: 16px !important;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  font-family: Lexend;
  display: inline-block;
  background-image: linear-gradient(160deg,#4b3eff 0,#22b1ff 99%);
}
.btn-primary::before{
  width: 100px;
  height: 100%;
  content: "";
  top: 0;
  right: -70px;
  z-index: -1;
  position: absolute;
  border-radius: 30px;
  background-color: #3848ee;
  -webkit-transition: .3s cubic-bezier(.24,.74,.58,1);
  transition: .3s cubic-bezier(.24,.74,.58,1);
}
.btn-primary:hover::before {
  -webkit-transform: scale(10);
  transform: scale(10);
  background-color: rgba(255,255,255,.08);
}
.btn-primary i, .btn-primary svg {
  margin-left: 10px;
  -webkit-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
}
/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
h1, .h1, h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
  color: var(--dark);
  font-weight: 600 !important;
  font-family: "Lexend", Sans-serif !important;
}

/* Style for h1 */
h1, h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

/* Style for h2 */
h2, h2 {
  font-size: 28px;
  margin-bottom: 8px;
}

/* Style for h3 */
h3, h3 {
  font-size: 24px;
  margin-bottom: 6px;
}

/* Style for h4 */
h4, h4 {
  font-size: 20px;
  margin-bottom: 4px;
}

/* Style for h5 */
h5, h5 {
  font-size: 17px;
  margin-bottom: 2px;
}

/* Style for h6 */
h6, .h6 {
  font-size: 15px;
  margin-bottom: 2px;
}
p, .p{
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--dark);
  font-weight: 400;
}
p:last-child{
  margin-bottom: 0;
}
a {
  color: var(--primary) ;
  text-decoration: none !important;
}
img{
  display: block;
  width: 100%;
}
/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/
.navbar-brand{
  width: 180px;
}
.navbar-expand-lg .navbar-nav .nav-link.btn{
  color: var(--white);
  background-color: var(--primary);
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar{
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.navbar .nav-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 !important;
  font-size: 15px;
  font-weight: 600;
  color: var(--dark);
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
  margin-right: 28px;
  border-bottom: 2px solid transparent; 
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show, .navbar-nav .nav-link:hover {
  color: var(--primary)!important;
  border-bottom: 2px solid var(--primary);
}

/*--------------------------------------------------------------
# Dropdown Menu
--------------------------------------------------------------*/

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  border-radius: 0;
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--primary)!important;
  text-decoration: none;
  background-color: var(--white)!important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: var(--white)!important;
  background-color: var(--primary)!important;
  transition: background-color 0.5s ease;
}
.dropdown-item {
  padding: 8px 20px !important;
  font-weight: 400 !important;
}

/*--------------------------------------------------------------
# Listing
--------------------------------------------------------------*/
ul, ol{
  margin: 0;
  padding: 0;
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
footer{
  font-size: 0.85rem;
  background-color: var(--primary);
   color: var(--white);
}

footer .footer-body{
  padding: 60px 0 40px 0;
}
footer .footer-body h5{
  text-transform: uppercase;
}
footer .footer-body p{
  color: var(--white);
}
footer .footer-body ul{
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}
footer .footer-body ul li b{
  margin-right: 10px;
}
footer .footer-body ul li, footer .footer-body ul li a{
  color: var(--white);
  text-decoration: none;
  margin-bottom: 5px;
}
footer .footer-body ul li a:hover{
  text-decoration: underline;
}
footer .company img{
  width:60%;
  margin-bottom: 10px;
}
footer .copyright{
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
}
footer .copyright a{
  color: var(--white);
  text-decoration: none;
}
/*--------------------------------------------------------------
# Social Media
--------------------------------------------------------------*/
.topbar{
  font-size: 0.85rem;
  background-color: var(--primary);
  color: var(--white);
  padding: 8px 0;
}
.topbar ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.topbar ul li{
  margin-right: 10px;
}
.topbar ul li:last-child{
  margin-right: 0px;
}
.topbar ul li b{
  margin-right: 5px;
}
.topbar ul li, .topbar ul li a{
  color: var(--white);
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .topbar {
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .topbar {
    background-color: var(--danger);
    display: none;
  }
}
/*--------------------------------------------------------------
# Social Media
--------------------------------------------------------------*/
.social {
  list-style-type: none;
  margin-bottom: 0;
}
.social li{
  float: left;
  margin-right: 7px;
  padding: 2px;
  border: 1px solid var(--white);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 0.9rem;
  font-size: 0.8rem;
}
.social li:hover{
  background-color: var(--danger);
}
.social li a {
  color: var(--white);
  text-decoration: none;
  margin-bottom: 5px;
}
/*--------------------------------------------------------------
# Back to Top
--------------------------------------------------------------*/
.back-to-top-button{
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  text-align: center;
  background-color: var(--primary);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  transition: opacity 0.3s ease;
}
.back-to-top-button:hover {
  opacity: 0.8;
}

/*--------------------------------------------------------------
# Header Slider
--------------------------------------------------------------*/
.header-slider .carousel-caption {
  text-align: left;
}
.header-slider .carousel-caption h1 {
  font-size: 5rem;
  font-weight: 500 !important;
  color: #fff;
}
.header-slider .carousel-caption p{
  font-size: 1.5rem;
  color: #fff;
}
.header-slider .carousel-caption.video-slider{
  top: 0;
  left: 0;
  padding: 0 15%;
  right: 0;
  height: 100%;
  background-color: transparent;
    background-image: linear-gradient(198deg, #2115be70 0%, #044466c5 100%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    display: flex;
}
.header-slider .carousel-inner {
  height: 84vh;
}
.header-slider .carousel-item{
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*--------------------------------------------------------------
# Inner Header
--------------------------------------------------------------*/
.innerHeader{
  padding: 140px 0 70px 0;
  background-size: cover;
}
.innerHeader h1{
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: var(--white);
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .card-container{
  text-align: center;
  display: block;
}
.contact .card-container .card{
  height: 100%;
}
.contact .card-container .card .card-body{
  text-align: left;
}

.contact .card-container .card .card-body .card-title{
  text-transform: uppercase;
  font-weight: 600;
}
.contact .card-container .card .card-body .card-text{
  font-size: 0.9rem;
}
.contact .card-container .card .icon {
  margin-bottom: 15px;
  z-index: 1;
  width: 70px;
  height: 70px;
  line-height: 80px;
  border-radius: 6px;
  margin-bottom: 30px;
  background-image: linear-gradient(-160deg,rgba(75,62,255,.3) 0,rgba(34,177,255,.3) 99%);
  justify-content: center;
  align-items: center;
  display: flex;
}
.contact .card-container .card:hover .icon{
  transition: .3s cubic-bezier(.24,.74,.58,1);
  background-image: linear-gradient(-160deg,#4b3eff 0,#22b1ff 99%);
}
.contact .card-container .card:hover .icon i, .contact .card-container .card:hover .icon svg {
  color: var(--white);
}
.contact .card-container .card .icon i, .contact .card-container .card .icon svg {
  font-size: 34px;
  line-height: 1;
  color: var(--primary);
  transition: all 0.3s ease-in-out;
}

.card{
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  background-color: var(--white);
}
section:nth-child(odd) {background-color: var(--light);}

.section-bg {
  background-color: var(--light);
}

.section-title {
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: var(--primary);
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
}

.section-title h3 span {
  color: var(--primary)
}



/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients, .clients-slider {
  padding: 15px 0;
  text-align: center;
}

.clients img,
.clients-slider img {
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}
.clients img{
  max-width: 45%;
}
.clients-slider img {
  max-width: 75%;
}

.clients img:hover,
.clients-slider img:hover {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 60%;
  }
  .clients-slider img {
    max-width: 80%;
  }
}

/*--------------------------------------------------------------
# Looking for the Best
--------------------------------------------------------------*/
.lookingforbest{
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  display: flex;
  justify-content: center;
}
.lookingforbest .container{
  background-color: transparent;
  background-image: linear-gradient(198deg, #4B3EFF 0%, #22B1FF 100%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-bottom: 0px;
  /* margin-top: -120px; */
  padding: 50px 40px 50px 40px;
  border-radius: 10px 10px 10px 10px;
  color: var(--white);
  font-family: "Lexend", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  /* position: absolute; */
}
.lookingforbest .container h1:last-child{
  margin-bottom: 0;
}
/*--------------------------------------------------------------
# Card
--------------------------------------------------------------*/
.card{
  position: relative;
  overflow: hidden;
  background: var(--white);
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12) !important;
  transition: all 0.3s ease-in-out !important;
  border-radius: 8px !important;
  border: 0 !important;
  z-index: 1;
  width:100%;
}
.card .card-body{
  padding: 20px;
}
/*--------------------------------------------------------------
# Services Card
--------------------------------------------------------------*/
.services-card .card{
  text-align: center;
}
.services-card img{
  width: 70%;
  margin: 0 auto;
}
.services-card .card-text{
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.whyus .card img{
  width: 100%;
}
/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services .icon-box {
  padding: 20px;
  position: relative;
  overflow: hidden;
  background: var(--white);
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  width:100%;
}


.featured-services .icon {
  margin-bottom: 15px;
  z-index: 1;
  width: 70px;
  height: 70px;
  line-height: 80px;
  border-radius: 6px;
  margin-bottom: 30px;
  background-image: linear-gradient(-160deg,rgba(75,62,255,.3) 0,rgba(34,177,255,.3) 99%);
  justify-content: center;
  align-items: center;
  display: flex;
}
.featured-services .icon-box:hover .icon{
  transition: .3s cubic-bezier(.24,.74,.58,1);
  background-image: linear-gradient(-160deg,#4b3eff 0,#22b1ff 99%);
}
.featured-services .icon i, .featured-services .icon svg {
  font-size: 34px;
  line-height: 1;
  color: var(--primary);;
  transition: all 0.3s ease-in-out;
}

.featured-services .icon-box:hover .icon i, .featured-services .icon-box:hover .icon svg {
  color: var(--white);
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content .row .card-item {
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 15px 20px 15px;
}
.about .content .row .card-item:hover{
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
}
.about .content .row .card-item p:last-child{
  margin-bottom:0 ;
}
.about-img img{
  border-radius: 20px;
  width: 90%;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
}
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services{
  background-color: var(--darkblue) !important;
  color: var(--white);
}
.services p{
  color: var(--white);
}
.setvice-item{
  flex: 1;
}
.setvice-item-bg{
  z-index: 1;
  overflow: hidden;
  background-color: #060f20;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.setvice-item-body {
  display: inline-block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.setvice-item-body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(198deg, #4B3EFF 0%, #22B1FF 100%);
  transform: scaleX(0);
  transition: transform 0.5s ease;
  transition: .5s cubic-bezier(.24,.74,.58,1);
}

.setvice-item-body:hover::before {
  transform: scaleX(1);
}

.setvice-item-body .icon-box {
  position: relative;
  z-index: 1;
  padding: 20px;
  transition: all ease-in-out 0.3s;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.services .icon-box .icon {
   width: 64px;
  height: 64px;
  border-radius: 4px;
  border: 1px solid var(--skyblue);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s; 
  font-size: 32px;
  background-image: linear-gradient(-160deg, #4b3eff 0%, #22b1ff 99%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #22b1ff;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
  color: var(--white);
}

.services .icon-box h4 a {
  color: var(--white);
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: var(--white);
}

.services .icon-box:hover h4 a,
.services .icon-box:hover .icon i,
.services .icon-box:hover .icon svg {
  color: var(--white);
}

.services .icon-box:hover .icon {
  color: #fff;
  background-color: transparent;
}

/*--------------------------------------------------------------
# Nav Tabs 
--------------------------------------------------------------*/
.nav-tabs{
  border: 0 !important;
  padding-bottom: 10px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color: #fff !important;
  background-image: linear-gradient(-160deg,#4b3eff 0,#22b1ff 99%);
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  border: 0;
}
.nav-tabs .nav-link{
  font-size: 22px;
  color: #000715 !important;
  font-weight: 600;
  font-family: Lexend;
  background-color: #fff;
  padding: 20px 40px 18px;
  border-radius: 6px !important;
  -webkit-box-shadow: 0 20px 40px 0 rgba(166,166,166,.1);
  box-shadow: 0 20px 40px 0 rgba(166,166,166,.1);
}


/*--------------------------------------------------------------
# Counter
--------------------------------------------------------------*/
.counter{
    background-color: #000715!important;
}
.counter .item{
  text-align: center;
}
.counter .item i, .counter .item svg {
  font-size: 55px;
  color:#22b1ff;
}
.counter .item h3{
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 3px;
  margin: 0;
}
.counter .item p {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 0;
}
/*--------------------------------------------------------------
# Projects
--------------------------------------------------------------*/

.projects {
  background-color: var(--white);
  color: var(--dark);
}
.projects p, .project .icon-box p{
  color: var(--dark) !important;
}

.projects .nav.nav-tabs {
  padding-right: 0;
  margin-bottom: 1rem;
}
.projects .tab-content{
  background: var(--white);
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  padding: 1.5rem 1.5rem 0 1.5rem;
}
.projects .icon-box {
  position: relative;
  overflow: hidden;
  background: var(--white);
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  width: 100%;
  color: var(--dark);
}
.projects .icon-box .box-body{
  padding: 20px;
}

.projects .icon-box .thumbnail{
  padding: 20px;
  background-image:url('../src/img/thumbnail-bg.jpg');
  background-size: cover;
  max-width: 100%;
  height: auto;
  display: flex;
  height: 160px;
  align-items: center;
  justify-content: center;
}
.projects .icon-box .thumbnail img{
  width: 100%;
}

/*--------------------------------------------------------------
# Product Item
--------------------------------------------------------------*/
.product-item .card{
  width: 100%;
  overflow: hidden;
}
.product-item .card .icon{
  width: 64px;
  height: 64px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  font-size: 32px;
  background-image: linear-gradient(198deg,rgba(75,62,255,.3) 0,rgba(34,177,255,.3) 99%);
  
  color: var(--primary);
}
.product-item:hover .icon {
  transition: .9s cubic-bezier(.24,.74,.58,1);
  background-image: linear-gradient(198deg,rgba(239, 238, 252, 0.5) 0, #ffffff 100%);
}
.solution-item .card .icon{
  margin-bottom: 0 !important;
}
@media screen and (min-width: 768px) {
  .solution-item .card .icon{
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------------------
# Careers
--------------------------------------------------------------*/
.job-listing{
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: block;
}
.job-listing:not(:last-child) {
  border-bottom: 1px solid var(--border);
}
/*--------------------------------------------------------------
# Privacy Policy
--------------------------------------------------------------*/
.jems-privacy::first-letter, .jems-privacy p::first-letter, .jems-privacy li::first-letter {
  text-transform: capitalize;
}